import store from '@/store'
import { useRouter } from '@core/utils/utils'

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = productId => {
    return store.dispatch('app-ecommerce/addProductInWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('app-ecommerce/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch('app-ecommerce/addProductInCart', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = (productId , isAll) => {
    const quantity = isAll ? undefined : 1
    return store.dispatch('app-ecommerce/removeProductFromCart', { productId, quantity })
  }

  const fetchCartProducts = () =>{
    return new Promise((resolve, reject) => {
      store.dispatch('app-ecommerce/fetchCartProducts')
      .then(response => {
        response.data.products.forEach(
          e => {
            e.price = e.price_total;
            e.qty = e.quantity;
            e.qty_bf = e.quantity;
            e.qty_blck = false;
          }
        )
      resolve(response.data);
      }).catch((error)=>{
        reject(error);
      })
    })
  }

  const fetchBusinessMD = () =>{
    return new Promise((resolve, reject) => {
      store.dispatch('app-ecommerce/fetchBusiness')
      .then(response => {
        response.data.products.forEach(
          e => {
            e.price = e.price_total;
            e.qty = e.quantity;
            e.qty_bf = e.quantity;
            e.qty_blck = false;
          }
        )
      resolve(response.data);
      }).catch((error)=>{
        reject(error);
      })
    })
  }

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
    fetchCartProducts,
    fetchBusinessMD
  }
}

export const useEcommerceUi = () => {
  const { router } = useRouter()

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }

  const handleCartActionClick = product => {
    const { addProductInCart } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      router.push({ name: 'apps-e-commerce-checkout' })
      return addProductInCart(product.uuid)//.then(() => {
        // eslint-disable-next-line no-param-reassign

        // Update cart items count
        //store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
      //})
    }
  }

  const handleWishlistCartActionClick = (product, removeProductFromWishlistUi) => {
    const { addProductInCart, removeProductFromWishlist } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.uuid)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true
          // removeProductFromWishlist(product.id)

          // Update cart items count
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          // product.isInWishlist = false
          // removeProductFromWishlistUi(product)
        })
        router.push({ name: 'apps-e-commerce-checkout' })
    }
  }

  const handleSpinButton = (product) => {
    return new Promise((resolve, reject) => {
      const {addProductInCart , removeProductFromCart} = useEcommerce();
      const operationTypes = {
        pass:"pass",
        add:"add",
        remove:"remove"
      }
      
      let operation = "pass";
      if(product.qty !== product.qty_bf){
        operation = product.qty - product.qty_bf > 0 ? "add": "remove";
      }
      
      if(operation !== operationTypes.pass){
        product.qty_blck = true;
        if(operation === operationTypes.add){
          addProductInCart(product.uuid)
            .then(response => {
              product.qty_bf = product.qty;
              product.qty_blck = false;
              resolve(response);
            }).catch( error =>{
              product.qty = product.qty_bf;
              product.qty_blck = false;
              reject(error);
            })
        }else if (operation === operationTypes.remove) {
          removeProductFromCart(product.uuid, false)
            .then(response => {
              product.qty_bf = product.qty;
              product.qty_blck = false;
              resolve(response);
            }).catch( error =>{
              product.qty = product.qty_bf;
              product.qty_blck = false;
              reject(error);
            })
        }
      }else{
        resolve("OK");
      }
    })

  }

  const removeProductFromChart = (product_uuid) => {
    const {removeProductFromCart} = useEcommerce();
    return new Promise((resolve, reject) => {
      removeProductFromCart(product_uuid, true)
            .then(response => {
              resolve(response);
            }).catch( error =>{
              reject(error);
            })
    })
  }

  const fetchBusiness = () => {
    const {fetchBusinessMD} = useEcommerce();
    return new Promise((resolve, reject) => {
        fetchBusinessMD ()
            .then(response => {
              resolve(response);
            }).catch( error =>{
              reject(error);
            })
    })
  }

  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
    handleSpinButton,
    removeProductFromChart,
    fetchBusiness
  }
}
