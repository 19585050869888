<template>
    <section class="ecommerce-product">
        <b-col class="container-product">
            <b-row align-h="center">
                <b-col >
                <div class="text-center">
                    <h3 >{{ product.description }}</h3>
                
                    <div >{{ product.name }}</div>
                </div>
                </b-col>
            </b-row>

            <hr>

            <b-row align-h="center">
                <b-col >
                <div class="pricing-price text-center row-product">                                            
                    <!--<p ><span class="currency">${{ product.price }}</span><span class="time"> MXN</span></p>-->                    
                    <p><span class="currency">{{ formatter(product.price) }}</span><span class="time"> MXN</span></p>                    
                </div>
                </b-col>
            </b-row>

            <hr>

            <b-row align-h="center">
                <b-col >
                <div class="pricing-price text-center row-product">
                    <p ><span class="currency">{{ product.tokens * product.qty}}</span><span class="time"> {{t('invoice')}}</span></p>    
                </div>
                </b-col>
            </b-row>

            <!-- <b-row  align-h="center">
                <b-col class="row-product list-product">
                    <span class="quantity-title">{{t('quantity')}}:</span>
                    <div style="margin-left: 50px;" v-if="product.qty_blck">
                        <b-icon 
                        icon="three-dots"
                        size="12"
                        animation="cylon"
                        />
                    </div>
                    <b-form-spinbutton v-else
                        :disabled="block"
                        v-model="product.qty"
                        size="sm"
                        class="ml-75"
                        inline
                        @change="spinbuttonHandler(product)"
                    />
                </b-col>
            </b-row> -->

            <b-row>
                <b-col class="text-center">
                    <b-button
                        :disabled="block"
                        variant="danger"
                        class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                        @click="removeProduct(product.uuid)"
                        >
                        <span style="margin-right: 15px;">
                            <b-icon 
                            icon="trash"
                            size="12"
                            />
                        </span>
                        
                        <span>{{t('remove')}}</span>
                    </b-button>
                </b-col>
            </b-row>

            <b-row  align-h="center">
                <b-col class="row-product list-product">
                <div>
                    ✅ {{ t('inlcuding.taxes') }}
                </div>
                <div>
                    ✅ {{ t('no.expiration') }}
                </div>
                </b-col>
            </b-row>

        </b-col>
    </section>
</template>

<script>
import {
 BRow, BCol,  BButton, BIcon, BFormSpinbutton
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BIcon,
    BFormSpinbutton
  },
  props:{
    product:{
      type: Object,
      required: true
    },
    removeProduct:{
      type: Function,
      required: true
    },
    spinbuttonHandler:{
      type: Function,
      required: true
    },
    block:{
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { t } = useI18nUtils();
    return {
        t
    }
  },
  methods:{
    formatter(price){
        const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price);
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-product.scss";
</style>
