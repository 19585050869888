<template>

  <div>
    <b-row align-h="center">
      <e-commerce-checkout-product
      v-for="product in products"
      :key="product.id"
      :product="product"
      :removeProduct="removeProduct"
      :spinbuttonHandler="spinbuttonHandler"
      :block="block"
      no-body
    />
    </b-row>
  </div>

</template>

<script>

import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,BRow
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import {BIcon} from 'bootstrap-vue'
import ECommerceCheckoutProduct from './ECommerceCheckoutProduct.vue'

export default {
  components: {
    BRow, BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton, ECommerceCheckoutProduct
  },
  props:{
    setAmount:{
      type: Function,
      required: true
    },
    setItems:{
      type: Function,
      required: true
    },
    setTicketUuid:{
      type: Function,
      required: true
    },
    setPending:{
      type: Function,
      required: true
    },
    block:{
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const products = ref([])
    const { toggleProductInWishlist, handleSpinButton, removeProductFromChart } = useEcommerceUi()
    const { fetchCartProducts } = useEcommerce()

    const fetchProduct = () => {
      fetchCartProducts()
      .then( response => {
        products.value = response.products
        props.setAmount(response.amount);
        props.setItems(response.products);
        props.setTicketUuid(response.ticket_uuid);
        props.setPending( response.status === 'pending_contingency');
      }).catch(() => {
        router.push({path:"/"});
      })
    }

    fetchProduct();

    const removeProduct = (product_uuid) =>{
      router.push({path:"/"});
      // removeProductFromChart(product_uuid)
      //   .then( response => {
      //     fetchCartProducts()
      //       .then( response => {
      //         products.value = response.products;
      //         props.setAmount(response.amount);
      //         props.setItems(response.products);
      //         props.setTicketUuid(response.ticket_uuid);
      //       })
      //   })
    }

    return {
      products,
      // UI
      toggleProductInWishlist,
      removeProduct,
      handleSpinButton,

      // Filter
      formatDate,

      //store
      fetchCartProducts
    }
  },
  methods:{
    spinbuttonHandler(product){
      this.handleSpinButton(product)
        .then( res => {
          this.fetchCartProducts()
            .then( response => {
              this.products = response.products;
              this.setAmount(response.amount);
              this.setItems(response.products);
              this.setTicketUuid(response.ticket_uuid);
            })
        });
    }
  }
}
</script>

<style>

</style>
