export default [
        {k: 'MEX' , v: 'México', or: 1, fz: '[0-9]{5}', ft: '[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]'},
        {k: 'USA' , v: 'Estados Unidos (los)', or: 2, fz: '[0-9]{5}(-[0-9]{4})?', ft: '[0-9]{9}'},
        {k: 'CAN' , v: 'Canadá', or: 3, fz: '[A-Z][0-9][A-Z] [0-9][A-Z][0-9]', ft: '[0-9]{9}'},
        {k: 'DEU' , v: 'Alemania', or: 4, fz: '.*', ft: '.*'},
        {k: 'CHN' , v: 'China', or: 5, fz: '.*', ft: '.*'},
        {k: 'ESP' , v: 'España', or: 6, fz: '.*', ft: '.*'},
        {k: 'FRA' , v: 'Francia', or: 7, fz: '.*', ft: '.*'},
        {k: 'ARG' , v: 'Argentina', or: 8, fz: '.*', ft: '.*'},
        {k: 'CUB' , v: 'Cuba', or: 9, fz: '.*', ft: '.*'},
        {k: 'HKG' , v: 'Hong Kong', or: 10, fz: '.*', ft: '.*'},
        {k: 'BRA' , v: 'Brasil', or: 11, fz: '.*', ft: '.*'},
        {k: 'GBR' , v: 'Reino Unido (el)', or: 12, fz: '.*', ft: '.*'},
        {k: 'JPN' , v: 'Japón', or: 13, fz: '.*', ft: '.*'},
        {k: 'AFG' , v: 'Afganistán', or: 14, fz: '.*', ft: '.*'},
        {k: 'ALA' , v: 'Islas Åland', or: 15, fz: '.*', ft: '.*'},
        {k: 'ALB' , v: 'Albania', or: 16, fz: '.*', ft: '.*'},
        {k: 'AND' , v: 'Andorra', or: 17, fz: '.*', ft: '.*'},
        {k: 'AGO' , v: 'Angola', or: 18, fz: '.*', ft: '.*'},
        {k: 'AIA' , v: 'Anguila', or: 19, fz: '.*', ft: '.*'},
        {k: 'ATA' , v: 'Antártida', or: 20, fz: '.*', ft: '.*'},
        {k: 'ATG' , v: 'Antigua y Barbuda', or: 21, fz: '.*', ft: '.*'},
        {k: 'SAU' , v: 'Arabia Saudita', or: 22, fz: '.*', ft: '.*'},
        {k: 'DZA' , v: 'Argelia', or: 23, fz: '.*', ft: '.*'},
        {k: 'ARM' , v: 'Armenia', or: 24, fz: '.*', ft: '.*'},
        {k: 'ABW' , v: 'Aruba', or: 25, fz: '.*', ft: '.*'},
        {k: 'AUS' , v: 'Australia', or: 26, fz: '.*', ft: '.*'},
        {k: 'AUT' , v: 'Austria', or: 27, fz: '.*', ft: '.*'},
        {k: 'AZE' , v: 'Azerbaiyán', or: 28, fz: '.*', ft: '.*'},
        {k: 'BHS' , v: 'Bahamas (las)', or: 29, fz: '.*', ft: '.*'},
        {k: 'BGD' , v: 'Bangladés', or: 30, fz: '.*', ft: '.*'},
        {k: 'BRB' , v: 'Barbados', or: 31, fz: '.*', ft: '.*'},
        {k: 'BHR' , v: 'Baréin', or: 32, fz: '.*', ft: '.*'},
        {k: 'BEL' , v: 'Bélgica', or: 33, fz: '.*', ft: '.*'},
        {k: 'BLZ' , v: 'Belice', or: 34, fz: '.*', ft: '.*'},
        {k: 'BEN' , v: 'Benín', or: 35, fz: '.*', ft: '.*'},
        {k: 'BMU' , v: 'Bermudas', or: 36, fz: '.*', ft: '.*'},
        {k: 'BLR' , v: 'Bielorrusia', or: 37, fz: '.*', ft: '.*'},
        {k: 'MMR' , v: 'Myanmar', or: 38, fz: '.*', ft: '.*'},
        {k: 'BOL' , v: 'Bolivia, Estado Plurinacional de', or: 39, fz: '.*', ft: '.*'},
        {k: 'BIH' , v: 'Bosnia y Herzegovina', or: 40, fz: '.*', ft: '.*'},
        {k: 'BWA' , v: 'Botsuana', or: 41, fz: '.*', ft: '.*'},
        {k: 'BRN' , v: 'Brunéi Darussalam', or: 42, fz: '.*', ft: '.*'},
        {k: 'BGR' , v: 'Bulgaria', or: 43, fz: '.*', ft: '.*'},
        {k: 'BFA' , v: 'Burkina Faso', or: 44, fz: '.*', ft: '.*'},
        {k: 'BDI' , v: 'Burundi', or: 45, fz: '.*', ft: '.*'},
        {k: 'BTN' , v: 'Bután', or: 46, fz: '.*', ft: '.*'},
        {k: 'CPV' , v: 'Cabo Verde', or: 47, fz: '.*', ft: '.*'},
        {k: 'KHM' , v: 'Camboya', or: 48, fz: '.*', ft: '.*'},
        {k: 'CMR' , v: 'Camerún', or: 49, fz: '.*', ft: '.*'},
        {k: 'QAT' , v: 'Catar', or: 50, fz: '.*', ft: '.*'},
        {k: 'BES' , v: 'Bonaire, San Eustaquio y Saba', or: 51, fz: '.*', ft: '.*'},
        {k: 'TCD' , v: 'Chad', or: 52, fz: '.*', ft: '.*'},
        {k: 'CHL' , v: 'Chile', or: 53, fz: '.*', ft: '.*'},
        {k: 'CYP' , v: 'Chipre', or: 54, fz: '.*', ft: '.*'},
        {k: 'COL' , v: 'Colombia', or: 55, fz: '.*', ft: '.*'},
        {k: 'COM' , v: 'Comoras', or: 56, fz: '.*', ft: '.*'},
        {k: 'PRK' , v: 'Corea (la República Democrática Popular de)', or: 57, fz: '.*', ft: '.*'},
        {k: 'KOR' , v: 'Corea (la República de)', or: 58, fz: '.*', ft: '.*'},
        {k: 'CIV' , v: 'Cote dIvoire', or: 59, fz: '.*', ft: '.*'},
        {k: 'CRI' , v: 'Costa Rica', or: 60, fz: '.*', ft: '.*'},
        {k: 'HRV' , v: 'Croacia', or: 61, fz: '.*', ft: '.*'},
        {k: 'CUW' , v: 'Curacao', or: 62, fz: '.*', ft: '.*'},
        {k: 'DNK' , v: 'Dinamarca', or: 63, fz: '.*', ft: '.*'},
        {k: 'DMA' , v: 'Dominica', or: 64, fz: '.*', ft: '.*'},
        {k: 'ECU' , v: 'Ecuador', or: 65, fz: '.*', ft: '.*'},
        {k: 'EGY' , v: 'Egipto', or: 66, fz: '.*', ft: '.*'},
        {k: 'SLV' , v: 'El Salvador', or: 67, fz: '.*', ft: '.*'},
        {k: 'ARE' , v: 'Emiratos Árabes Unidos (Los)', or: 68, fz: '.*', ft: '.*'},
        {k: 'ERI' , v: 'Eritrea', or: 69, fz: '.*', ft: '.*'},
        {k: 'SVK' , v: 'Eslovaquia', or: 70, fz: '.*', ft: '.*'},
        {k: 'SVN' , v: 'Eslovenia', or: 71, fz: '.*', ft: '.*'},
        {k: 'EST' , v: 'Estonia', or: 72, fz: '.*', ft: '.*'},
        {k: 'ETH' , v: 'Etiopía', or: 73, fz: '.*', ft: '.*'},
        {k: 'PHL' , v: 'Filipinas (las)', or: 74, fz: '.*', ft: '.*'},
        {k: 'FIN' , v: 'Finlandia', or: 75, fz: '.*', ft: '.*'},
        {k: 'FJI' , v: 'Fiyi', or: 76, fz: '.*', ft: '.*'},
        {k: 'GAB' , v: 'Gabón', or: 77, fz: '.*', ft: '.*'},
        {k: 'GMB' , v: 'Gambia (La)', or: 78, fz: '.*', ft: '.*'},
        {k: 'GEO' , v: 'Georgia', or: 79, fz: '.*', ft: '.*'},
        {k: 'GHA' , v: 'Ghana', or: 80, fz: '.*', ft: '.*'},
        {k: 'GIB' , v: 'Gibraltar', or: 81, fz: '.*', ft: '.*'},
        {k: 'GRD' , v: 'Granada', or: 82, fz: '.*', ft: '.*'},
        {k: 'GRC' , v: 'Grecia', or: 83, fz: '.*', ft: '.*'},
        {k: 'GRL' , v: 'Groenlandia', or: 84, fz: '.*', ft: '.*'},
        {k: 'GLP' , v: 'Guadalupe', or: 85, fz: '.*', ft: '.*'},
        {k: 'GUM' , v: 'Guam', or: 86, fz: '.*', ft: '.*'},
        {k: 'GTM' , v: 'Guatemala', or: 87, fz: '.*', ft: '.*'},
        {k: 'GUF' , v: 'Guayana Francesa', or: 88, fz: '.*', ft: '.*'},
        {k: 'GGY' , v: 'Guernsey', or: 89, fz: '.*', ft: '.*'},
        {k: 'GIN' , v: 'Guinea', or: 90, fz: '.*', ft: '.*'},
        {k: 'GNB' , v: 'Guinea-Bisáu', or: 91, fz: '.*', ft: '.*'},
        {k: 'GNQ' , v: 'Guinea Ecuatorial', or: 92, fz: '.*', ft: '.*'},
        {k: 'GUY' , v: 'Guyana', or: 93, fz: '.*', ft: '.*'},
        {k: 'HTI' , v: 'Haití', or: 94, fz: '.*', ft: '.*'},
        {k: 'HND' , v: 'Honduras', or: 95, fz: '.*', ft: '.*'},
        {k: 'HUN' , v: 'Hungría', or: 96, fz: '.*', ft: '.*'},
        {k: 'IND' , v: 'India', or: 97, fz: '.*', ft: '.*'},
        {k: 'IDN' , v: 'Indonesia', or: 98, fz: '.*', ft: '.*'},
        {k: 'IRQ' , v: 'Irak', or: 99, fz: '.*', ft: '.*'},
        {k: 'IRN' , v: 'Irán (la República Islámica de)', or: 100, fz: '.*', ft: '.*'},
        {k: 'IRL' , v: 'Irlanda', or: 101, fz: '.*', ft: '.*'},
        {k: 'BVT' , v: 'Isla Bouvet', or: 102, fz: '.*', ft: '.*'},
        {k: 'IMN' , v: 'Isla de Man', or: 103, fz: '.*', ft: '.*'},
        {k: 'CXR' , v: 'Isla de Navidad', or: 104, fz: '.*', ft: '.*'},
        {k: 'NFK' , v: 'Isla Norfolk', or: 105, fz: '.*', ft: '.*'},
        {k: 'ISL' , v: 'Islandia', or: 106, fz: '.*', ft: '.*'},
        {k: 'CYM' , v: 'Islas Caimán (las)', or: 107, fz: '.*', ft: '.*'},
        {k: 'CCK' , v: 'Islas Cocos (Keeling)', or: 108, fz: '.*', ft: '.*'},
        {k: 'COK' , v: 'Islas Cook (las)', or: 109, fz: '.*', ft: '.*'},
        {k: 'FRO' , v: 'Islas Feroe (las)', or: 110, fz: '.*', ft: '.*'},
        {k: 'SGS' , v: 'Georgia del sur y las islas sandwich del sur', or: 111, fz: '.*', ft: '.*'},
        {k: 'HMD' , v: 'Isla Heard e Islas McDonald', or: 112, fz: '.*', ft: '.*'},
        {k: 'FLK' , v: 'Islas Malvinas [Falkland] (las)', or: 113, fz: '.*', ft: '.*'},
        {k: 'MNP' , v: 'Islas Marianas del Norte (las)', or: 114, fz: '.*', ft: '.*'},
        {k: 'MHL' , v: 'Islas Marshall (las)', or: 115, fz: '.*', ft: '.*'},
        {k: 'PCN' , v: 'Pitcairn', or: 116, fz: '.*', ft: '.*'},
        {k: 'SLB' , v: 'Islas Salomón (las)', or: 117, fz: '.*', ft: '.*'},
        {k: 'TCA' , v: 'Islas Turcas y Caicos (las)', or: 118, fz: '.*', ft: '.*'},
        {k: 'UMI' , v: 'Islas de Ultramar Menores de Estados Unidos (las)', or: 119, fz: '.*', ft: '.*'},
        {k: 'VGB' , v: 'Islas Vírgenes (Británicas)', or: 120, fz: '.*', ft: '.*'},
        {k: 'VIR' , v: 'Islas Vírgenes (EE.UU.)', or: 121, fz: '.*', ft: '.*'},
        {k: 'ISR' , v: 'Israel', or: 122, fz: '.*', ft: '.*'},
        {k: 'ITA' , v: 'Italia', or: 123, fz: '.*', ft: '.*'},
        {k: 'JAM' , v: 'Jamaica', or: 124, fz: '.*', ft: '.*'},
        {k: 'JEY' , v: 'Jersey', or: 125, fz: '.*', ft: '.*'},
        {k: 'JOR' , v: 'Jordania', or: 126, fz: '.*', ft: '.*'},
        {k: 'KAZ' , v: 'Kazajistán', or: 127, fz: '.*', ft: '.*'},
        {k: 'KEN' , v: 'Kenia', or: 128, fz: '.*', ft: '.*'},
        {k: 'KGZ' , v: 'Kirguistán', or: 129, fz: '.*', ft: '.*'},
        {k: 'KIR' , v: 'Kiribati', or: 130, fz: '.*', ft: '.*'},
        {k: 'KWT' , v: 'Kuwait', or: 131, fz: '.*', ft: '.*'},
        {k: 'LAO' , v: 'Lao, (la) República Democrática Popular', or: 132, fz: '.*', ft: '.*'},
        {k: 'LSO' , v: 'Lesoto', or: 133, fz: '.*', ft: '.*'},
        {k: 'LVA' , v: 'Letonia', or: 134, fz: '.*', ft: '.*'},
        {k: 'LBN' , v: 'Líbano', or: 135, fz: '.*', ft: '.*'},
        {k: 'LBR' , v: 'Liberia', or: 136, fz: '.*', ft: '.*'},
        {k: 'LBY' , v: 'Libia', or: 137, fz: '.*', ft: '.*'},
        {k: 'LIE' , v: 'Liechtenstein', or: 138, fz: '.*', ft: '.*'},
        {k: 'LTU' , v: 'Lituania', or: 139, fz: '.*', ft: '.*'},
        {k: 'LUX' , v: 'Luxemburgo', or: 140, fz: '.*', ft: '.*'},
        {k: 'MAC' , v: 'Macao', or: 141, fz: '.*', ft: '.*'},
        {k: 'MDG' , v: 'Madagascar', or: 142, fz: '.*', ft: '.*'},
        {k: 'MYS' , v: 'Malasia', or: 143, fz: '.*', ft: '.*'},
        {k: 'MWI' , v: 'Malaui', or: 144, fz: '.*', ft: '.*'},
        {k: 'MDV' , v: 'Maldivas', or: 145, fz: '.*', ft: '.*'},
        {k: 'MLI' , v: 'Malí', or: 146, fz: '.*', ft: '.*'},
        {k: 'MLT' , v: 'Malta', or: 147, fz: '.*', ft: '.*'},
        {k: 'MAR' , v: 'Marruecos', or: 148, fz: '.*', ft: '.*'},
        {k: 'MTQ' , v: 'Martinica', or: 149, fz: '.*', ft: '.*'},
        {k: 'MUS' , v: 'Mauricio', or: 150, fz: '.*', ft: '.*'},
        {k: 'MRT' , v: 'Mauritania', or: 151, fz: '.*', ft: '.*'},
        {k: 'MYT' , v: 'Mayotte', or: 152, fz: '.*', ft: '.*'},
        {k: 'FSM' , v: 'Micronesia (los Estados Federados de)', or: 153, fz: '.*', ft: '.*'},
        {k: 'MDA' , v: 'Moldavia (la República de)', or: 154, fz: '.*', ft: '.*'},
        {k: 'MCO' , v: 'Mónaco', or: 155, fz: '.*', ft: '.*'},
        {k: 'MNG' , v: 'Mongolia', or: 156, fz: '.*', ft: '.*'},
        {k: 'MNE' , v: 'Montenegro', or: 157, fz: '.*', ft: '.*'},
        {k: 'MSR' , v: 'Montserrat', or: 158, fz: '.*', ft: '.*'},
        {k: 'MOZ' , v: 'Mozambique', or: 159, fz: '.*', ft: '.*'},
        {k: 'NAM' , v: 'Namibia', or: 160, fz: '.*', ft: '.*'},
        {k: 'NRU' , v: 'Nauru', or: 161, fz: '.*', ft: '.*'},
        {k: 'NPL' , v: 'Nepal', or: 162, fz: '.*', ft: '.*'},
        {k: 'NIC' , v: 'Nicaragua', or: 163, fz: '.*', ft: '.*'},
        {k: 'NER' , v: 'Níger (el)', or: 164, fz: '.*', ft: '.*'},
        {k: 'NGA' , v: 'Nigeria', or: 165, fz: '.*', ft: '.*'},
        {k: 'NIU' , v: 'Niue', or: 166, fz: '.*', ft: '.*'},
        {k: 'NOR' , v: 'Noruega', or: 167, fz: '.*', ft: '.*'},
        {k: 'NCL' , v: 'Nueva Caledonia', or: 168, fz: '.*', ft: '.*'},
        {k: 'NZL' , v: 'Nueva Zelanda', or: 169, fz: '.*', ft: '.*'},
        {k: 'OMN' , v: 'Omán', or: 170, fz: '.*', ft: '.*'},
        {k: 'NLD' , v: 'Países Bajos (los)', or: 171, fz: '.*', ft: '.*'},
        {k: 'PAK' , v: 'Pakistán', or: 172, fz: '.*', ft: '.*'},
        {k: 'PLW' , v: 'Palaos', or: 173, fz: '.*', ft: '.*'},
        {k: 'PSE' , v: 'Palestina, Estado de', or: 174, fz: '.*', ft: '.*'},
        {k: 'PAN' , v: 'Panamá', or: 175, fz: '.*', ft: '.*'},
        {k: 'PNG' , v: 'Papúa Nueva Guinea', or: 176, fz: '.*', ft: '.*'},
        {k: 'PRY' , v: 'Paraguay', or: 177, fz: '.*', ft: '.*'},
        {k: 'PER' , v: 'Perú', or: 178, fz: '.*', ft: '.*'},
        {k: 'PYF' , v: 'Polinesia Francesa', or: 179, fz: '.*', ft: '.*'},
        {k: 'POL' , v: 'Polonia', or: 180, fz: '.*', ft: '.*'},
        {k: 'PRT' , v: 'Portugal', or: 181, fz: '.*', ft: '.*'},
        {k: 'PRI' , v: 'Puerto Rico', or: 182, fz: '.*', ft: '.*'},
        {k: 'CAF' , v: 'República Centroafricana (la)', or: 183, fz: '.*', ft: '.*'},
        {k: 'CZE' , v: 'República Checa (la)', or: 184, fz: '.*', ft: '.*'},
        {k: 'MKD' , v: 'Macedonia (la antigua República Yugoslava de)', or: 185, fz: '.*', ft: '.*'},
        {k: 'COG' , v: 'Congo', or: 186, fz: '.*', ft: '.*'},
        {k: 'COD' , v: 'Congo (la República Democrática del)', or: 187, fz: '.*', ft: '.*'},
        {k: 'DOM' , v: 'República Dominicana (la)', or: 188, fz: '.*', ft: '.*'},
        {k: 'REU' , v: 'Reunión', or: 189, fz: '.*', ft: '.*'},
        {k: 'RWA' , v: 'Ruanda', or: 190, fz: '.*', ft: '.*'},
        {k: 'ROU' , v: 'Rumania', or: 191, fz: '.*', ft: '.*'},
        {k: 'RUS' , v: 'Rusia, (la) Federación de', or: 192, fz: '.*', ft: '.*'},
        {k: 'ESH' , v: 'Sahara Occidental', or: 193, fz: '.*', ft: '.*'},
        {k: 'WSM' , v: 'Samoa', or: 194, fz: '.*', ft: '.*'},
        {k: 'ASM' , v: 'Samoa Americana', or: 195, fz: '.*', ft: '.*'},
        {k: 'BLM' , v: 'San Bartolomé', or: 196, fz: '.*', ft: '.*'},
        {k: 'KNA' , v: 'San Cristóbal y Nieves', or: 197, fz: '.*', ft: '.*'},
        {k: 'SMR' , v: 'San Marino', or: 198, fz: '.*', ft: '.*'},
        {k: 'MAF' , v: 'San Martín (parte francesa)', or: 199, fz: '.*', ft: '.*'},
        {k: 'SPM' , v: 'San Pedro y Miquelón', or: 200, fz: '.*', ft: '.*'},
        {k: 'VCT' , v: 'San Vicente y las Granadinas', or: 201, fz: '.*', ft: '.*'},
        {k: 'SHN' , v: 'Santa Helena, Ascensión y Tristán de Acuña', or: 202, fz: '.*', ft: '.*'},
        {k: 'LCA' , v: 'Santa Lucía', or: 203, fz: '.*', ft: '.*'},
        {k: 'STP' , v: 'Santo Tomé y Príncipe', or: 204, fz: '.*', ft: '.*'},
        {k: 'SEN' , v: 'Senegal', or: 205, fz: '.*', ft: '.*'},
        {k: 'SRB' , v: 'Serbia', or: 206, fz: '.*', ft: '.*'},
        {k: 'SYC' , v: 'Seychelles', or: 207, fz: '.*', ft: '.*'},
        {k: 'SLE' , v: 'Sierra leona', or: 208, fz: '.*', ft: '.*'},
        {k: 'SGP' , v: 'Singapur', or: 209, fz: '.*', ft: '.*'},
        {k: 'SXM' , v: 'Sint Maarten (parte holandesa)', or: 210, fz: '.*', ft: '.*'},
        {k: 'SYR' , v: 'Siria, (la) República Árabe', or: 211, fz: '.*', ft: '.*'},
        {k: 'SOM' , v: 'Somalia', or: 212, fz: '.*', ft: '.*'},
        {k: 'LKA' , v: 'Sri Lanka', or: 213, fz: '.*', ft: '.*'},
        {k: 'SWZ' , v: 'Suazilandia', or: 214, fz: '.*', ft: '.*'},
        {k: 'ZAF' , v: 'Sudáfrica', or: 215, fz: '.*', ft: '.*'},
        {k: 'SDN' , v: 'Sudán (el)', or: 216, fz: '.*', ft: '.*'},
        {k: 'SSD' , v: 'Sudán del Sur', or: 217, fz: '.*', ft: '.*'},
        {k: 'SWE' , v: 'Suecia', or: 218, fz: '.*', ft: '.*'},
        {k: 'CHE' , v: 'Suiza', or: 219, fz: '.*', ft: '.*'},
        {k: 'SUR' , v: 'Surinam', or: 220, fz: '.*', ft: '.*'},
        {k: 'SJM' , v: 'Svalbard y Jan Mayen', or: 221, fz: '.*', ft: '.*'},
        {k: 'THA' , v: 'Tailandia', or: 222, fz: '.*', ft: '.*'},
        {k: 'TWN' , v: 'Taiwán (Provincia de China)', or: 223, fz: '.*', ft: '.*'},
        {k: 'TZA' , v: 'Tanzania, República Unida de', or: 224, fz: '.*', ft: '.*'},
        {k: 'TJK' , v: 'Tayikistán', or: 225, fz: '.*', ft: '.*'},
        {k: 'IOT' , v: 'Territorio Británico del Océano Índico (el)', or: 226, fz: '.*', ft: '.*'},
        {k: 'ATF' , v: 'Territorios Australes Franceses (los)', or: 227, fz: '.*', ft: '.*'},
        {k: 'TLS' , v: 'Timor-Leste', or: 228, fz: '.*', ft: '.*'},
        {k: 'TGO' , v: 'Togo', or: 229, fz: '.*', ft: '.*'},
        {k: 'TKL' , v: 'Tokelau', or: 230, fz: '.*', ft: '.*'},
        {k: 'TON' , v: 'Tonga', or: 231, fz: '.*', ft: '.*'},
        {k: 'TTO' , v: 'Trinidad y Tobago', or: 232, fz: '.*', ft: '.*'},
        {k: 'TUN' , v: 'Túnez', or: 233, fz: '.*', ft: '.*'},
        {k: 'TKM' , v: 'Turkmenistán', or: 234, fz: '.*', ft: '.*'},
        {k: 'TUR' , v: 'Turquía', or: 235, fz: '.*', ft: '.*'},
        {k: 'TUV' , v: 'Tuvalu', or: 236, fz: '.*', ft: '.*'},
        {k: 'UKR' , v: 'Ucrania', or: 237, fz: '.*', ft: '.*'},
        {k: 'UGA' , v: 'Uganda', or: 238, fz: '.*', ft: '.*'},
        {k: 'URY' , v: 'Uruguay', or: 239, fz: '.*', ft: '.*'},
        {k: 'UZB' , v: 'Uzbekistán', or: 240, fz: '.*', ft: '.*'},
        {k: 'VUT' , v: 'Vanuatu', or: 241, fz: '.*', ft: '.*'},
        {k: 'VAT' , v: 'Santa Sede[Estado de la Ciudad del Vaticano] (la)', or: 242, fz: '.*', ft: '.*'},
        {k: 'VEN' , v: 'Venezuela, República Bolivariana de', or: 243, fz: '.*', ft: '.*'},
        {k: 'VNM' , v: 'Viet Nam', or: 244, fz: '.*', ft: '.*'},
        {k: 'WLF' , v: 'Wallis y Futuna', or: 245, fz: '.*', ft: '.*'},
        {k: 'YEM' , v: 'Yemen', or: 246, fz: '.*', ft: '.*'},
        {k: 'DJI' , v: 'Yibuti', or: 247, fz: '.*', ft: '.*'},
        {k: 'ZMB' , v: 'Zambia', or: 248, fz: '.*', ft: '.*'},
        {k: 'ZWE' , v: 'Zimbabue', or: 249, fz: '.*', ft: '.*'},
        {k: 'ZZZ' , v: 'Países no declarados', or: 250, fz: '.*', ft: '.*'}
    ] 