<template>
  <div >
    <h4>Productos</h4>
    <b-row align-h="start" v-if="Array.isArray(items)">
      <b-col cols="6">
        <e-commerce-checkout-step-cart-products-custom :setAmount="setAmount" :setItems="setItems" :setTicketUuid="setTicketUuid" :setPending="setPending" :block="Boolean(preferenceId)"/>
      </b-col>
      <b-col cols="5">
        <b-alert        
          variant="info"
          :show="isNotBusinessAccount"
        >
          <h2 class="alert-heading">
            {{ t("buy instead of") }}          
          </h2>
        </b-alert>
        <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <h4>{{ t('Invoice Data') }}</h4>
        <b-row align-h="end">
          <b-col lg="6">
            <!-- Tax ID -->
            <validation-provider
              #default="validationContext"
              ref="taxidFocusv"
              name="RFC"
              rules="required|min:12"
            >
              <b-form-group
                :label="t('Tax ID')"   
                ref="taxidFocusg"           
                label-for="taxid"
                :invalid-feedback="t('taxidr')"              
              >
                <b-form-input
                  id="taxid"
                  ref="taxidFocus"
                  v-model="contactData.taxid"
                  :autofocus=true
                  :formatter="fmtUppercaseAll"
                  max="13"
                  trim
                  disabled
                  placeholder="XAXX010101AAA"
                />
              </b-form-group>
            </validation-provider>

            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="Business Name"
              rules="required"
            >
              <b-form-group
                :label="t('Business Name')"
                label-for="username"
                :invalid-feedback="t('bnamereq')"
              >
                <b-form-input
                  id="username"
                  v-model="contactData.name"
                  disabled
                  :formatter="fmtUppercaseAll"
                  trim
                />

                <!--<b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>-->
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                :label="t('Email')"
                label-for="email"                            
                v-b-tooltip.hover.top="t('temail')"
                :invalid-feedback="t('emailr')"              
                >
                    <b-form-input
                      id="email" 
                      v-model="contactData.email"
                      disabled               
                      trim                  
                    >              
                  </b-form-input>
              </b-form-group>
              <!--<feather-icon icon="SearchIcon" />-->
            </validation-provider>

            <!-- Phone -->
            <validation-provider
              #default="validationContext"
              name="Phone"
              
            >
              <b-form-group
                :label="t('Phone')"
                label-for="phone"
                :invalid-feedback="t('phoner')"              
              >
                <b-form-input
                  id="phone"
                  v-model="contactData.phone"
                  disabled
                  type="number"
                  trim
                />
              </b-form-group>
            </validation-provider>

            <!-- TaxPayer Type -->
            <validation-provider
              #default="validationContext"
              name="TaxPayer Type"
              rules="required|catalog"
            >
              <b-form-group
                :label="t('TaxPayer Type')"
                label-for="taxpayerType"
              >
                <v-select
                  id="taxBandList"
                  v-model="contactData.taxertype"
                  :state="getValidationState(validationContext)"
                  :disabled="Boolean(['XAXX010101000','XEXX010101000'].find(a => a === contactData.taxid) || preferenceId)"
                  text-field="v"
                  label="v"
                  :options="contactData.taxpayerOptions"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Invoice Use"
              rules="required|catalog"
            >
              <b-form-group
                :label="t('Invoice Use')"
                label-for="invoiceUse"
              >
                <v-select
                  id="taxBandList"
                  v-model="contactData.invoiceuse"
                  :state="getValidationState(validationContext)"
                  :disabled="Boolean(['XAXX010101000','XEXX010101000'].find(a => a === contactData.taxid) || preferenceId || !contactData.taxertype)"
                  text-field="v"
                  label="v"
                  :options="contactData.taxertype ? invoiceuseList.filter(c => ( c.sr.find(u => u === contactData.taxertype.k))): undefined"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- **************************  -->
        <div class="price-details">
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                <H1>{{ t("Total") }}</H1>
              </div>
              <div>
                <p><span class="currency">{{ formatter(amount) }}</span><span class="time"> MXN</span></p>
                <!--${{ amount }}-->
              </div>
            </li>
          </ul>
          
          <div v-if="preferenceId" id="wallet_container">
          </div>
          <b-button v-else-if="contactData.address.zip !== ''"
            variant="primary"
            block
            @click="createPreference"
            :disabled="!validateForm()"
            size="lg"
          >
            {{t("checkout.ok")}}
          </b-button>
          <b-button v-else
                variant="danger"
                :to="{ name: 'fg-account-settings'}"
              >
                <span class="text-nowrap">{{ t('Complete Profile First') }}</span>
              </b-button>
        </div>
          </b-col>

        <b-col lg="6">
            <!-- ZIP -->
          <validation-provider
            #default="validationContext"
            name="ZIP"
            rules="required|min:5"
          >
            <b-form-group
              :label="t('Zip')"
              label-for="zip"
              :invalid-feedback="t('zipr')"
            >
              <b-form-input
                id="zip"
                v-model="contactData.address.zip"
                disabled
                trim
                type="number"
              />
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <b-form-group
            :label="t('Country')"
            label-for="countryList"            
          >
            <v-select
              id="countryList"
              v-model="contactData.address.country"
              :disabled="contactData.taxid !== 'XEXX010101000'"
              text-field="v"
              label="v"
            />
          </b-form-group>

          <!-- State -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            :label="t('State')"
            label-for="stateList"            
          >
            <v-select
              id="stateList"
              disabled
              v-model="contactData.address.state"
              text-field="v"
              label="v"
            />
          </b-form-group>

          <!-- Town -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            :label="t('Town')"
            label-for="townList"            
          >
            <v-select
              id="townList"
              disabled
              v-model="contactData.address.town"
              text-field="v"
              label="v"
            />
          </b-form-group>

          <!-- Suburb -->
          <b-form-group
            v-if="contactData.taxid !== 'XEXX010101000'"
            :label="t('Suburb')"
            label-for="suburbList"
            v-b-tooltip.hover.top="t('tcolonia')"
          >
            <v-select
              id="suburbList"
              v-model="contactData.address.suburb"
              disabled
              text-field="v"
              label="v"
            />
          </b-form-group>

          <!-- Street -->
          <b-form-group
            :label="contactData.taxid !== 'XEXX010101000' ? t('Street') : t('Direccion')"
            label-for="address-street"
          >
            <b-form-input
              id="street"
              disabled
              v-model="contactData.address.street"
              
            />
          </b-form-group>
        </b-col>
      </b-row>  
          
        </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend,
  BForm, BFormGroup, VBTooltip, BIcon, BFormInvalidFeedback, BAlert
} from 'bootstrap-vue'
import store from '@/store';
import { ref } from '@vue/composition-api';
import ECommerceCheckoutStepCartProductsCustom from './ECommerceCheckoutStepCartProductsCustom.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceuseList from '@/@catalogs/sat/c_usocfdi';
import taxpayerList from '@/@catalogs/sat/c_regimenfiscal';
import countryList from '@/@catalogs/sat/c_pais';
import vSelect from 'vue-select';
import formValidation from '@core/comp-functions/forms/form-validation'

const mp = new MercadoPago('APP_USR-c108d9b0-2ee0-4e9c-916d-067da94d979a'); /* PRODUCCION */
//const mp = new MercadoPago('APP_USR-b143ee19-ae64-41f0-b5ba-dc1f185f2217'); /* SANDBOX */
const bricksBuilder = mp.bricks();

export default {
  components: {
    // BSV
    BAlert,
    BRow,
    BCol,
    BButton,
    BCard,
    BInputGroup,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BIcon,
    //Form
    ValidationProvider,
    ValidationObserver,
    // SFC
    ECommerceCheckoutStepCartProductsCustom,
    vSelect
  },
  directives:{
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      invoiceuseList,
      taxpayerList,
      countryOption: countryList,
      isNotBusinessAccount: false
    }
  },
  setup(){
    const amount = ref("");
    const items = ref([]);
    const ticketUuid = ref("");
    const pending = ref(false)
    const preferenceId = ref(undefined)
    const setAmount = (value) => {
      amount.value = value;
      preferenceId.value = undefined;
    }

    const setItems = (value) => {
      items.value = value;
    }

    const setTicketUuid = (value) => {
      ticketUuid.value = value;
    }

    const setPending = (value) => {
      pending.value = value;
    }

    const { t } = useI18nUtils()

    const onSubmit = () =>{console.log("on submit");}

    const contactData = ref({
      taxid: '', // RFC
      name: '', // Razon Social
      taxertype: { // Regimen Fiscal
        k: '',
        v: ''
      },
      taxpayerOptions:[],
      invoiceuse: { // Uso de la Factura
        k: '',
        v: ''
      },
      email: '', // Correo Electronico
      phone: '', // Telefono
      address: {
        street: '',
        suburb: {
          k: '',
          v: ''
        },
        town: {
          k: '',
          v: ''
        },
        state: {
          k: '',
          v: ''
        },
        country: countryList.find(a => a.k === 'MEX'),
        zip: '',
      }
    });

    store.dispatch('app-ecommerce/fetchBusiness')
        .then(response => {
          const business = response.data.business
          contactData.value.taxid = business.taxid;
          contactData.value.name = business.name;
          const isTxPayerArray = Array.isArray(business.taxpayer);
          const taxpayer = isTxPayerArray? business.taxpayer[0] : business.taxpayer;
          const taxpayerOptions = isTxPayerArray? business.taxpayer: [business.taxpayer]
          contactData.value.taxertype = taxpayer;
          contactData.value.invoiceuse = invoiceuseList.filter(c => ( c.sr.find(u => u === taxpayer.k)))[0];
          contactData.value.taxpayerOptions = taxpayerOptions;
          contactData.value.email = business.email;
          contactData.value.phone = business.phone;
          contactData.value.address.street = business.address.main.street;
          contactData.value.address.suburb = business.address.main.suburb;
          contactData.value.address.town = business.address.main.town;
          contactData.value.address.state = business.address.main.state;
          contactData.value.address.country = business.address.main.country;
          contactData.value.address.zip = business.address.main.zip;  
        })
        .catch(error => {
          console.log(error);
        })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(()=>{})

    return {
      setItems,
      setAmount,
      setTicketUuid,
      setPending,
      amount,
      items,
      ticketUuid,
      pending,
      onSubmit,
      contactData,
      t,
      preferenceId,
      refFormObserver,
      getValidationState
    }
  },
  updated() {
    // if(this.items.length === 0 ){
    //   this.$router.push({path:"/"})
    //   console.log("length");
    // }
     if(this.pending){
      this.$router.push({path:"/"})
      console.log("pending");
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Pago en proceso`,
          icon: 'CreditCardIcon',
          variant: 'info',
          text: `En cuanto tu pago sea procesado exitosamente podrás continuar comprando`,
        },
      },{
        timeout: 10000,
      })
    }
  },
  mounted (){
    if(window?.paymentBrickController){
      window?.paymentBrickController?.unmount();
    }

    const session = JSON.parse(localStorage.getItem("session"));

    const accountName = session.account.name.toLowerCase().replace(/\s/g, "");
    const businessName = session.business.name.toLowerCase().replace(/\s/g, "");

    if(accountName === businessName){
      this.isNotBusinessAccount = false
    }else{
      this.isNotBusinessAccount = true;
    }
  },
  methods:{
    formatter(price){
        const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price);
    },
    validateForm(){
      const result = Boolean(this.contactData.taxertype.k &&
        this.contactData.name &&
        this.contactData.taxid &&
        this.contactData.invoiceuse.k &&
        this.contactData.email &&
        this.contactData.address.zip)
      return result
    },
    createPreference(){
      const customer = {
        customer: {
          name: this.contactData.name,
          taxid: this.contactData.taxid,
          email: this.contactData.email,
          phone: this.contactData.phone,
          taxregime: {
              k: this.contactData.taxertype.k
          },
          invoiceuse: {
              k: this.contactData.invoiceuse.k
          },
          address: {
              street: this.contactData.address.street,
              zip: this.contactData.address.zip
          }
        }
      }
      if(!this.validateForm()){
        return;
      }
      store.dispatch('app-ecommerce/createInvoice', customer)
        .then(response => {
          const request = {
            ticket_uuid: this.ticketUuid,
            items: this.items.map( e => ({
              id: e.uuid,
              title: e.name,
              quantity: e.qty,
              unit_price: e.price_unit
            }))
          }
          store.dispatch('app-ecommerce/createPreference', request)
          .then(async response => {
            this.preferenceId = response.data.uuid
            window.paymentBrickController = await bricksBuilder.create("wallet", "wallet_container", {
              locale: this.$t("locale"),
              initialization: {
                preferenceId: this.preferenceId,
                redirectMode: "modal",
              },
              customization: {
                visual: {
                    borderRadius: '12px',
                },
                texts: {
                    action: 'pay',
                    valueProp: 'security_details',
                }
              }
            });
          })
        });
    },
    fmtUppercaseAll(value) {
        let v = value.toUpperCase()        
        return v
    },
  }
}
</script>